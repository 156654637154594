<template>
  <div class="box">
    <div class="tabbar" :class="navBarFixed ? 'navBarFixed' : ''">
      <img @click="goback" src="../../../../static/common/logo.png" alt="" />
    </div>
    <img class="banner" src="/subject/sc_2024/banner.png" alt="" />
    <div id="login">
      <div class="logxiadan">
        <img src="/subject/sc_2024/500zj.png" alt="" />
      </div>
      <div class="interactive">
        <input
          v-model="form.phone"
          ref="inputElement"
          type="text"
          placeholder="请输入手机号"
        />
        <div class="codeBox">
          <input v-model="form.code" type="text" placeholder="请输入验证码" />
          <span v-if="verControl" @click="verCode(form.phone)">获取验证码</span>
          <span v-else>重新获取({{ verSecond }})</span>
        </div>
        <img
          class="loginBtn"
          @click="submit(form)"
          src="/subject/sc_2024/loginBtn.png"
          alt=""
        />
      </div>
      <div v-if="controlImgCodeDialog" class="codeDialog">
        <img :src="imgcodelocal" alt="" />
        <p>
          <span>图形验证码:</span>
          <input type="text" v-model="form.codeNumber" />
        </p>
        <div>
          <button
            @click="
              () => {
                this.controlImgCodeDialog = false;
              }
            "
          >
            取消
          </button>
          <button @click="confirm(form.codeNumber)">确定</button>
        </div>
      </div>
      <div class="logdjs">
        倒计时 <span class="logdjs-span">{{ days }}</span
        >天<span class="logdjs-span">{{ hours }}</span
        >时<span class="logdjs-span">{{ minutes }}</span
        >分<span class="logdjs-span">{{ seconds }}</span
        >秒
      </div>
    </div>

    <div class="content">
      <div class="part">
        <div class="part1">
          <div style="padding-top: 183px;">
            <div class="clearfix newuser"></div>
            <div class="clearfix">
              <span class="span1" @click="showDialog = true">活动详情</span>
            </div>
            <div class="img-box1">
              <div class="img1" @click="focusInput">
                <img src="/subject/sc_2024/100.png" alt="" />
              </div>
              <div class="img2" @click="focusInput">
                <img src="/subject/sc_2024/300.png" alt="" />
              </div>
              <div class="img3" @click="focusInput">
                <img src="/subject/sc_2024/200.png" alt="" />
              </div>
            </div>
            <div class="neirong">
                <span class="b1">
                    <img src="/subject/sc_2024/gold.png" alt="" />
                </span>
                <h1>美元人民币双福利 充值越多送越多</h1>
                <p>（马上咨询客服 领取人民币红包奖励）</p>
                 <span class="b2">
                    <img src="/subject/sc_2024/gold.png" alt="" />
                </span>
                <p class="p2">新客专享现金福利<br/>交易笔笔返赠，每笔交易领$2-$5</p>
            </div>
            <div class="anniu">
              <div class="anniu-div" @click="live800"></div>
              <img
                class="anniu-img"
                @click="focusInput"
                src="/subject/sc_2024/lzj.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="part2 clearfix">
          <div class="line260"></div>
          <div class="part2-img">
            <img src="/subject/sc_2024/step.png" alt="">
          </div>
          <div class="part2-img2">
            <img src="/subject/sc_2024/step1.png" alt="">
          </div>
          <div class="anniu">
            <div class="anniu-div" @click="live800"></div>
            <img
              class="anniu-img"
              @click="focusInput"
              src="/subject/sc_2024/lzj.png"
              alt=""
            />
          </div>
        </div>
        <div class="part3">
          <div class="line260"></div>
          <div class="con">
            <ul>
                <li>
                     <img src="/subject/sc_2024/ss1.png" alt="">
                </li>
                 <li>
                     <img src="/subject/sc_2024/ss2.png" alt="">
                </li>
            </ul>

            <ul>
                <li>
                     <img src="/subject/sc_2024/ss3.png" alt="">
                </li>
                 <li>
                     <img src="/subject/sc_2024/ss4.png" alt="">
                </li>
            </ul>
            <p>
                <b>专业安全</b>的投资平台 引领您走向<b>成功</b><br/>
                <b>赢</b>在交易<b>起跑线</b>   驱动新年<b>财富增长</b>
            </p>
          </div>
          <div class="anniu">
            <div class="anniu-div" @click="live800"></div>
            <img
              class="anniu-img"
              @click="focusInput"
              src="/subject/sc_2024/lzj.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="sliderBox" v-if="slider">
      <img class="allCode" src="/subject/invest/downloadNew.png" alt="" />
      <img
        @click="slider = false"
        class="user-info-close"
        src="../../../../static/common/user-info-close.png"
        alt=""
      />
    </div>
    <div v-if="showDialog" class="dialog">
      <img
        class="closer"
        @click="showDialog = false"
        src="/subject/mayPage/closer.png"
        alt=""
      />
      <ul class="dialogContent">
        <h3>新用户活动规则</h3>
        <li>
          <span>活动对象：</span><br />
          活动期间，在本平台首次开立真实账户，及首次入金的客户
        </li>
        <li>
          <span>活动时间：</span><br />
          即日起至{{ formattedDateString }}
        </li>
        <li style="color:#ad0000">
          <span>活动规则：</span><br />
          活动期间，新用户可享双重福利：<br />
          1. 开户领$588美元赠金，交易后可提现；<br />
          2. 首次入金，最高领$20000+￥1588新人礼包！
        </li>
        <li>
          <span>开户领$588美元赠金：</span><br />
          完成首次开户 领$588美元赠金，有效期90天内每交易一手伦敦金/伦敦银，获得相对应的赠金金额返还（未满一手按照实际交易手数占一手的比例计算）<br />
          <img src="/subject/mayPage/table100.png" alt="" />
        </li>
        <li>
          <span>首入金领$20000+￥1588</span><br />
          首次充值，送美元赠金+美元现金+人民币红包；<br />
          24小时内充多少送多少，最高领$20000+￥1588 迎新大礼包；<br />
          首入金奖励如下：<br />
        </li>
        <li>
          <img src="/subject/mayPage/table200.png" alt="" />
        </li>
        <li>
          <span>美元赠金：</span><br />
          1、活动期间内，首次入金可获得等额的美元赠金（即赠金点）回馈；<br />
          2、
          首入金有效期为第一笔入金后的24h内，充多少送多少；<br />
          3、用户每交易一手伦敦金/伦敦银，返还相对应的赠金金额；该笔资金可交易可提现，用户可登录App-我的-赠金奖励中查看（未满一手按照实际交易手数占一手的比例计算）<br />
          4、美元赠金按交易手数返还，当月交易越多，每手返还越高！<br />
          <span style="color:#000000">
            更多活动详情，请下载App-我的-赠金奖励中查看，或咨询客服。
          </span>
        </li>
        <li>
          <span>美元现金：</span><br />
          1、美元现金直接入账，可交易，可提现；<br />
          2、用户在30天内完成指定交易手数即可提现，品种限伦敦金/伦敦银；<br />
          3、30天内未达到交易手数要求，或者活动期间取款时未完成对应手数交易，系统将收回美元现金金额；<br />
          举例说明：A女士，入金500美元，到账600美元，入金后30天内完成了20手交易，将100美元现金提现
        </li>
        <li>
          <span>注意事项：</span><br />
          1.
          客户参与活动需在本公司开立真实交易账户,参与该活动的新用户，为保证资金安全，在初始L1层级，账户强平比例上调至50%，升级后自动恢复为20%；<br />
          2. 交易手数计算：开仓一手平仓一手，如此来回算一手；<br />
          3. 本活动本公司新客户仅有一次机会参与，不可重复领取；<br />
          4.
          本公司保留随时修订、暂停、终止本活动及任何相关规则条款之权利及其解释权。
        </li>
      </ul>
    </div>
    <div class="dark" v-if="showDialog" @click="showDialog = false"></div>
    <contactus-vue></contactus-vue>
  </div>
</template>

<script>
import ContactusVue from "../../Contactus.vue";
import { getGuid } from "../../../../utils/guid";
import { verifyImgCode, quickRegister } from "../../../../api/info";
import { getJfConfigurationValue } from "../../../../api/subject/hdtime";
export default {
  components: {
    ContactusVue,
  },
  data() {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      navBarFixed: false,
      scroll: "",
      screenWidth: null,
      form: {
        phone: "",
        code: "",
        codeNumber: "",
      },
      // 控制显示验证码或读秒
      verControl: true,
      // 定时器倒计时
      refreshData: null,
      // 验证码读秒数
      verSecond: 60,
      // 控制图片验证码弹窗
      controlImgCodeDialog: false,
      // 图片验证码地址
      imgcodelocal: "",
      setStep: true,
      slider: true,
      showDialog: false,
      formattedDateString:'',
      timer: null,
    };
  },

  mounted() {
    //获取屏幕尺寸
    this.screenWidth = document.body.clientWidth;
    window.addEventListener("scroll", this.handleScroll);
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
    this.getConfigurationValue()
  },
  destroyed() {
    clearInterval(this.timer);
  },
  watch: {
    screenWidth: function(n) {
      if (n <= 500) {
        this.$router.push({
          name: "sc_2024wap",
        });
      }
    },
  },
  created() {
    this.$emit("controlShow", true);
    
  },
  methods: {
    async getConfigurationValue() {
        const res = await getJfConfigurationValue(JSON.stringify({"key":"SCjiezhishijian"}))
        const { Data } = res;
        this.updateCountdown(Data.replace(/-/g, '/'));
        this.timer = setInterval(() => this.updateCountdown(Data.replace(/-/g, '/')), 1000);
        this.formattedDateString = this.formatDates(Data);  
        console.log(this.formattedDateString,'this.formattedDateString')
    },
    formatDates(dateString) {  
        // 解析日期时间字符串  
      const date = new Date(dateString);  
    
      // 检查日期是否有效  
      if (isNaN(date.getTime())) {  
          throw new Error('Invalid date string');  
      }  
    
      // 使用模板字符串格式化日期和时间  
      const formattedDate = `${date.getFullYear()}年${padZero(date.getMonth() + 1)}月${padZero(date.getDate())}日${padZero(date.getHours())}:${padZero(date.getMinutes())}`;  
    
      return formattedDate;  
    
      // 辅助函数：在数字前面添加前导零（如果需要）  
      function padZero(num) {  
          return num.toString().padStart(2, '0');  
      }  
    },
    // 点击获取焦点
    focusInput() {
      this.$refs.inputElement.focus();
    },
    updateCountdown(needTime) {
      const today = new Date();
      const endOfMonth = new Date(needTime).getTime();
      // const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      let timeLeft = endOfMonth - today;
      if(timeLeft <= 0) {
        timeLeft = 0
        clearInterval(this.timer)
      }
      this.days = String(Math.floor(timeLeft / (1000 * 60 * 60 * 24))).padStart(
        2,
        "0"
      );
      this.hours = String(
        Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      ).padStart(2, "0");
      this.minutes = String(
        Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60))
      ).padStart(2, "0");
      this.seconds = String(
        Math.floor((timeLeft % (1000 * 60)) / 1000)
      ).padStart(2, "0");
    },
    // 跳转客服
    live800() {
      window.open(this.$parent.live800UrlUc);
    },
    // 返回上级页面
    goback() {
      window.location.href = window.location.origin;
    },
    // 控制顶部的定位
    handleScroll() {
      this.scroll =
        document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
      if (this.scroll > 90) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }
    },
    // 提交验证码
    confirm(attr) {
      if (attr) {
        let data = {
          Mobile: this.form.phone,
          Token: this.guid,
          ImgCode: Number(attr),
        };
        // 获取图片验证码接口
        verifyImgCode(data).then((res) => {
          if (res.ok == 1) {
            this.$message({
              message: res.msg,
              type: "warning",
            });
            // this.form.code = res.data;
            this.verControl = !this.verControl;
            this.controlImgCodeDialog = false;
          } else {
            this.$message({
              message: res.msg,
              type: "warning",
            });
            this.controlImgCodeDialog = false;
          }
        });
      } else {
        this.$message({
          message: "请输入图片验证码结果!",
          type: "warning",
        });
      }
    },
    // 提交手机号验证码
    submit(form) {
      if (form.phone && form.code) {
        let data = {
          IsQuickRregister: true,
          Mobile: form.phone,
          Code: form.code,
          Token: this.guid,
          LoginType: 1,
          AuthMode: 1,
        };
        quickRegister(data).then((res) => {
          if (res.ok == 1) {
            this.$message({
              message: res.msg,
              type: "warning",
            });
            window.location.href = "https://www.rlcproltd.net/uc/login";
          } else {
            this.$message({
              message: res.msg,
              type: "warning",
            });
          }
        });
      } else {
        this.$message({
          message: "请输入手机号和验证码！",
          type: "warning",
        });
      }
    },
    // 点击获取验证码
    verCode(attr) {
      if (this.isPhone(attr)) {
        this.controlImgCodeDialog = true;
        this.countdowm();
        this.guid = getGuid();
        // this.imgcodelocal = window.location.origin + '/api/proxy/v4/U104?key=' + this.guid;
        this.imgcodelocal =
          "https://sem.rlcmarketltd.net" +
          "/api/proxy/v4/U104?key=" +
          this.guid;
      } else {
        this.$message({
          message: "请输入正确手机号!",
          type: "warning",
        });
      }
    },
    // 正则判断电话号码格式是否正确
    isPhone(TEL) {
      let strTemp = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
      if (strTemp.test(TEL)) {
        return true;
      }
      return false;
    },
    // 读秒
    countdowm() {
      this.refreshData = window.setInterval(() => {
        this.verSecond--;
        if (this.verSecond < 0) {
          //当倒计时小于0时清除定时器
          window.clearInterval(this.refreshData); //清除定时器
          this.verSecond = 60;
          this.verControl = true;
        }
      }, 1000);
    },
    // 回到顶部
    toTop() {
      document.documentElement.scrollTop = 0;
    },
    //获取当月最后一天的日期
    getLastDay() {
      var year = new Date().getFullYear(); //获取年份
      var month = new Date().getMonth() + 1; //获取月份
      var lastDate = new Date(year, month, 0).getDate(); //获取当月最后一日
      month = month < 10 ? "0" + month : month; //月份补 0
      return year + "年" + month + "月" + lastDate + "日 23:59:59";
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  width: 100%;
  // overflow-x: hidden;
  // height: 6215px;
  background: #5D0000;
  .tabbar {
    width: 100%;
    background-color: #ffffff;

    img {
      margin: 20px 50px;
      width: 130px;
    }
  }

  .navBarFixed {
    position: fixed;
    top: 0;
    z-index: 999;
  }

  .banner {
    width: 100%;
    display: block;
  }

  #login {
    width: 80%;
    height: 18.5vw;
    margin: 0 auto;
    background: url(/subject/newYear9911/zcbj.png) no-repeat;
    background-size: 100% 100%;
    border-radius: 10px;
    padding: 25px 0;
    position: absolute;
    top: 30vw;
    left: 50%;
    transform: translate(-50%, 0);
    .logxiadan {
      width: 70%;
      margin: 0 auto;
      margin-bottom: 10px;
      img {
        width: 100%;
      }
    }
    .interactive {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      input:focus {
        outline: none;
      }
      input {
        width: 30%;
        height: 4vw;
        background: #fff;
        border: 1px solid #fff;
        border-radius: 10px;
        font-size: 20px;
        text-indent: 25px;
        list-style: none;
      }

      .codeBox {
        width: 38%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        input {
          width: 100%;
        }

        span {
          width: 160px;
          height: 3.5vw;
          line-height: 3.5vw;
          font-size: 20px;
          border-radius: 10px;
           background: linear-gradient(to bottom, #ffefcc, #ffc994);
          text-align: center;
          color: #C70812;
          position: absolute;
          top: 0.4vw;
          right: 15px;
          cursor: pointer;
        }
      }

      .loginBtn {
        width: 20%;
        cursor: pointer;
        position: relative;
        top: 15px;
      }
    }

    .codeDialog {
      width: 500px;
      height: 250px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fee6e4;
      border-radius: 15px;
      z-index: 99;
      border: 1px solid #ffdbdb;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      img {
        width: 120px;
        margin-bottom: 0;
      }

      p {
        span {
          color: #5f0e15;
          font-size: 18px;
          font-weight: 600;
        }

        input {
          width: 150px;
          height: 30px;
          background-color: #ffffff;
          border: none;
          margin-left: 10px;
          text-indent: 10px;
          color: #5f0e15;
          font-size: 16px;
        }
      }

      div {
        display: flex;
        justify-content: center;
        align-items: center;

        button {
          width: 100px;
          height: 40px;
          border: 1px solid #f64b44;
          background-color: #ffffff;
          color: #f64b44;
          font-size: 18px;
          border-radius: 8px;
          margin: 0 10px;
        }

        button:nth-child(2) {
          background-color: #f64b44;
          color: #ffffff;
        }
      }
    }

    .logdjs {
      // width: 70%;
      margin: 0 auto;
      height: 7.8vw;
      line-height: 7.8vw;
      text-align: center;
      font-size: 2vw;
      color: #fff2d5;
      opacity: 0.8;

      .logdjs-span {
        display: inline-block;
        width: 4.5vw;
        height: 4.7vw;
        margin: 0 5px;
        line-height: 4.7vw;
        text-align: center;
        font-size: 2.5vw;
        background-color: #942600;
        border-radius: 16px;
        border: 1px solid #ffffff;
        color: #FFF2D5;
      }
    }
  }

  .content {
    width: 100%;
    height: auto;
    // height: 5627px;
    box-sizing: border-box;
    margin: 0 auto;
    
    .parent-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .dujia {
        margin-top: 130px;
      }
    }

    .part {
      // max-width: 1400px;
      width: 73%;
      margin: 0 auto;
      .part1 {
        box-sizing: border-box;
        // width: 1377px;
        // height: 1497px;
        background: url(/subject/sc_2024/1.png) no-repeat center center;
        background-size: 100% 100%;
        margin: 0 auto;
        .clearfix {
          width: 105px;
          height: 40px;
          margin: 0 auto;
          margin-top: 45px;
          margin-bottom: 45px;
          .span1 {
            text-decoration: underline;
            // position: absolute;
            // top: -30px;
            // left: 0;
            // margin-top: -30px;
            display: block;
            // width: 33%;
            text-align: center;
            // margin-left: 371px;
            font-size: 25px;
            color: #c0705e;
            cursor: pointer;
          }
        }
        .clearfix::after {
          content: "";
          display: table;
          clear: both;
        }
        .newuser {
          width: 556px;
          height: 73px;
          margin: 0 auto;
          background: url(/subject/sc_2024/newuser.png) no-repeat center;
          background-size: 100%;
          margin-top: 60px;
        }

        .img-box1 {
          display: flex;
          justify-content: space-around;
          margin-bottom: 40px;
          .img1,.img2,.img3 {
            width: 33%;
            img {
              width: 100%;
            }
          }
        }

        .img-box2 {
          margin-bottom: 50px;
          padding-left: 100px;

          .jia {
            margin-bottom: 122px;
            margin-left: 50px;
          }
        }

        .youhui {
          font-size: 32px;
          color: #333;
          margin: 0 auto;
          width: 50%;
          height: 60px;
          line-height: 60px;
          text-align: center;
          display: flex;
          margin-bottom: 20px;

          img {
            margin-right: 10px;
          }

          i {
            font-style: normal;
            /* 取消斜体 */
            color: red;
            /* 设置文本颜色为红色 */
          }
        }

        .neirong {
          width: 780px;
          height: 188px;
          margin: 0 auto;
          text-align: center;
          position: relative;
          h1{
            color: #C0614B;
            font-size: 40px;
          }
          p{
            color: #E80F0F;
            font-size: 26px;
            font-weight: bold;
          }
          .p2{
            color: #C0614B;
            font-size: 32px;
            margin-top: 30px;
          }
          .b1{
            position: absolute;
            left: 0;
            top: 0;
          }
          .b2{
            position: absolute;
            right: 0;
            top: 0;
          }
        }

        .anniu {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 62px;
          .anniu-div {
            width: 362px;
            height: 155px;
            background: url(/subject/newYear9911/serve.png) no-repeat center;
            background-size: 100%;
          }
        }
      }

      .part2 {
        max-width: 1377px;
        // width: 1377px;
        // height: 1497px;
        margin: 0 auto;
        margin-top: 155px;
        padding: 0 50px;
        background: url(/subject/newYear9911/2.png) no-repeat center;
        background-size: 100% 100%;
        background-position: center;
        /* 填充整个小div */
        .clearfix::after {
          content: "";
          display: table;
          clear: both;
        }
        .line260 {
          width: 100%;
          height: 260px;
          margin: 0 auto;
        }
        .part2-img {
          // width: 1255px;
          // height: 360px;
          margin: 0 auto;
          width: 100%;
          img{
            width: 100%;
          }
          // background: url(/subject/newYear9911/step.png) no-repeat center;
          // background-size: 100%;
        }

        .part2-img2 {
          max-width: 921px;
          // width: 921px;
          // height: 689px;
          margin: 0 auto;
          // background: url(/subject/newYear9911/step1.png) no-repeat center;
          // background-size: 100%;
          margin-top: 57px;
          img {
            width: 100%;
          }
        }

        .part2-text {
          width: 100%;
          width: 50%;
          margin: 0 auto;
          font-size: 32px;
          color: #333333;

          .part2-text-span1 {
            color: #e26060;
          }

          .part2-text-span2 {
            color: #333333;
          }
        }

        .anniu {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          top: -40px;
          .anniu-div {
            width: 362px;
            height: 155px;
            background: url(/subject/newYear9911/serve.png) no-repeat center;
            background-size: 100%;
          }
        }
      }

      .part3 {
        max-width: 1377px;
        // width: 1377px;
        // height: 1718px;
        margin: 0 auto;
        margin-top: 100px;
        margin-bottom: 90px;
        background: url(/subject/newYear9911/3.png) no-repeat center;
        background-size: 100% 100%;
        background-position: center;
        padding-bottom: 30px;
        .line260 {
          max-width: 1377px;
          // width: 1377px;
          height: 260px;
          margin: 0 auto;
        }
        .con {
          width: 100%;
          margin: 0 auto;
          max-width: 1280px;
          box-sizing: border-box;
          padding: 0 50px;
          ul {
            margin-bottom: 30px;
            li{
            display: inline-block;
          }
          }
          
          ul li:nth-child(1){
            margin-right: 60px;
          }
          p{
            font-size: 36px;
            color: #73462F;
            text-align: center;
            b{
                font-weight: normal;
                color: #CC2A2A;
            }
          }
        }
        .anniu {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          top: 20px;
          .anniu-div {
            width: 362px;
            height: 155px;
            background: url(/subject/newYear9911/serve.png) no-repeat center;
            background-size: 100%;
          }
        }
      }
    }
  }

  .sliderBox {
    width: 239px;
    height: 373px;
    background: url(/subject/invest/dialog.png) no-repeat;
    background-size: 100% 100%;
    position: relative;
    position: fixed;
    top: 50%;
    right: 0;
    transform: translate(0%, -50%);
    text-align: center;
    line-height: 470px;

    .allCode {
      width: 140px;
    }

    .user-info-close {
      position: absolute;
      bottom: -60px;
      left: 50%;
      z-index: 5;
      transform: translate(-50%, 0);
      cursor: pointer;
    }
  }

  .dialog {
    position: relative;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    height: 580px;
    box-shadow: 0px 0px 95px 0px rgba(187, 188, 192, 0.1);
    background-color: #fff;
    border-radius: 14px;
    overflow-y: scroll;
    z-index: 99999999;

    .closer {
      width: 25px;
      height: auto;
      position: absolute;
      top: 8px;
      right: 8px;
      cursor: pointer;
    }

    .dialogContent {
      box-sizing: border-box;
      padding: 15px;
      overflow: hidden;
      background: url("/subject/mayPage/rulesBackground.png") no-repeat;
      background-size: 100% 100%;

      h3 {
        color: #d88f4f;
        text-align: center;
      }

      li {
        font-size: 16px;
        color: #464646;
        line-height: 34px;

        span {
          font-weight: 600;
          color: #d88f4f;
        }

        img {
          width: 100%;
          height: auto;
          margin: 20px 0;
        }

        table {
          width: 100%;
          border: 1px solid #464646;
          border-radius: 10px;

          tr {
            th {
              text-align: center;
              font-weight: 500;
              line-height: 24px;
              border-bottom: 1px solid #464646;
              border-right: 1px solid #464646;
              padding: 5px;
            }

            th:last-child {
              border-right: 1px solid transparent;
            }
          }

          tr:nth-child(1) {
            th {
              font-weight: 600;
            }
          }
        }
      }
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: #fedfba;
      -webkit-border-radius: 2em;
      -moz-border-radius: 2em;
      border-radius: 2em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d88f4f;
      -webkit-border-radius: 2em;
      -moz-border-radius: 2em;
      border-radius: 2em;
    }
  }

  .dark {
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 101;
  }
}
</style>
